import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

const MasterLayout = () => {
  return (
    <>
      <div
        className="leading-normal tracking-normal text-gray-900 flex flex-col min-h-screen flex-grow bg-center bg-cover bg-fixed"
        style={{ backgroundImage: "url('todak-arena-bg-min.jpg')" }}
      >
        <div className="flex-grow">
          {/* Header */}
          <Header />

          {/* Main Content */}
          <div className="container px-6 mx-auto flex flex-wrap flex-col md:flex-row items-center">
            <Outlet />
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    </>
  );
};

export default MasterLayout;
