import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MasterLayout from "./components/MasterLayout";
import Home from "./pages/home";
import Privacy from "./pages/privacy";
import Terms from "./pages/terms";
import About from "./pages/about";
import TournamentPopup from "./components/TournamentPopup";
import ContactUs from "./pages/contact-us";
import { useGoogleAnalytics } from "./useGoogleAnalytics";

function App() {
  if (process.env.NODE_ENV === "production") {
    console.log = () => {};
  }

  return (
    <>
      <BrowserRouter>
        <GoogleAnalyticsWrapper />
        <Routes>
          <Route element={<MasterLayout />}>
            <Route path="/" element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="privacy-policy" element={<Privacy />} />
            <Route path="terms-of-use" element={<Terms />} />
            <Route path="contact-us" element={<ContactUs />} />
          </Route>
        </Routes>
      </BrowserRouter>
      {/* <TournamentPopup /> */}
    </>
  );
}

// Create a wrapper component to use the hook
function GoogleAnalyticsWrapper() {
  useGoogleAnalytics();
  return null;
}

export default App;
